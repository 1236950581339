@import url('https://fonts.googleapis.com/css?family=Inter');

/* Default styles */
.Portfolio-Section {
    display: flex;
    flex-direction: column;
    font-family: "Inter";
}

.portfolio_top {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 60rem;
    background-color: #92786B;
    justify-content: flex-start;
    align-items: center;
    color: black;
}

.portfolio_bottom {
    position: relative;
    height: fit-content;
    width: 100vw;
}

.port_heading {
    font-size: 3rem;
    margin-top: 5%;
    margin-bottom: 5%;
}
.port_info{
    display: flex;
    font-size: 1.2rem;
    margin-left: 5%;
    flex-direction: column;
    align-items: flex-start;
}

.port_hero {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10%;
}

.port_panel {
    position: absolute;
    top: -12rem;
    right: 5%;
    left:5%;
    width:90vw;
}

hr {
    border: none;
    height: 2px;
    background-color: rgba(0, 0, 0, 1);
    width: 80%;
    margin: 1% auto;
}

.info-list{
    text-align: left;
    font-size: 1rem;
    list-style-type: none;
    width: 100%;
}

.info-list li::before {
    content: '-'; /* Custom marker */
    color: #000000; /* Custom color */
    position: relative;
    padding-right: 2%;
  }
  .info-footer{
    font-size: 1rem;
    text-align: left;
    padding-right: 5%;
  }

/* Media queries for responsiveness */
@media only screen and (max-width: 1000px) {
    .port_hero {
        flex-direction: column;
        height:60%; /* Stack items vertically on small screens */
    }

    .port_panel {
        position: absolute; 
        margin-top: 20%; /* Adjust spacing as needed */
        top:0;
    }
    .line{
        margin-top: 10%;
    }
}

@media only screen and (max-width: 480px) {
    .portfolio_top {
        height: 30rem; /* Adjust height for smaller screens */
    }

    .port_heading {
        font-size: 2rem; /* Adjust font size for smaller screens */
    }

    .port_panel {
        right: 10%;
        left:auto;
    }
}
