.info_content{
    display: flex;
    flex-direction: column;
}

.info_hero{
    display: flex;
    justify-content: space-between;
}

.info_hero_left{
    display: flex;
    flex-direction: column;
    padding: 5%;
    justify-content: flex-start;
    padding-right: 0;
}
.left_desc{
    font-size: 1.2rem;
}
.left_info{
    display: flex;
    justify-content: flex-start;
}
.info_item{
    display: flex;
    justify-content: flex-start;
    width:50%
}

.right_photo{
    width:100%;
    height:120%;
    position: absolute;
    top:0;
    right:10px;
    object-fit:cover ;
}

.right_bg{
    width:40rem;
    height:40rem;
}

.info_hero_right{
    position: relative;
}

.project_title{
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
    display: flex;
    align-items: flex-start;
}

.item_title{
    font-weight: bold;
    padding-right: 5%;
}

.left_desc{
    width:80%;
    align-self: flex-start;
    text-align: left;
    margin-top: 5%;
}

.info_desc{
    display: flex;
    flex-direction: column;
    width:100vw;
    height:auto;
    align-items: center;
}

.info_desc_bottom , .info_desc_top{
    margin-top: 20%;
    height:50%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10%;
}

.info_disp{
    height:40rem;
    position: relative;
    width:50vw;
}

.disp1 , .disp2 , .disp3 , .disp4{
    width:42.5vw;
    position: absolute;
    object-fit: cover;
}

.disp1{
    top:0;
    left:10%;
    height:30vw;
}
.disp2{
    bottom:-40;
    right:10%;
    height:73vw;
}
.disp3{
    bottom:0;
    left:10%;
    height:150vh;
}
.disp4{
    bottom: 0%;
    right:10%;
    height:44vw;
}
.info_desc_top{
    margin-top: 30%;
}
.info_desc_bottom{
    margin-top: 25%;
}

@media only screen and (max-width: 1000px) {
    .info_hero{
        flex-direction: column;
    }
    .right_photo{
        position: relative;
        height:100vw;
        right:0;
    }
    .info_hero_left{
        align-self: center;
        margin-left: 15%;
        padding: 0;
    }
    .info_hero_right{
        order:-1;
    }
    .right_bg{
        position: absolute;
        visibility: hidden;
    }

    }

    @media only screen and (max-width: 480px) {
        .left_info{
            flex-direction: column;
            width: 100%;
        }
        .info_item{
            width: 100%;
        }
        }
    @media only screen and (max-width: 700px) {
        .info_desc_top , .info_desc_bottom{
            flex-direction: column;
            width: 100vw;
            justify-content: space-evenly;
        }

        .disp1 , .disp2 , .disp3 , .disp4{
            width:100%;
            position: static;
            top:none;
            right: none;
            height: 100%;
        }
        .info_disp{
            width: 100vw;
            margin-bottom: 20%;
        }

        .info_desc_bottom{
            margin-top: 0;
        }
        }

    
