.Footer_Section{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10%;
    text-align:left;
}
.Logo_footer{
    display:flex;
    justify-content: center;
    align-items: flex-start;
}

.circle_footer{
    width:2rem;
    height:2rem;
    background: black;
    border-radius: 50%;
    margin-right: 5px;
}
.footer_box{
    margin-top: 5%;
    width:20%
}
.footer_text{
    font-weight: 50;
    font-size: 1.1rem;
    font-family: "Inter";
}

.logo_text{
    margin:0;
    margin-top: 0.5rem;
    font-family: "Inter";
}

.footer_heading{
    font-weight: bold;
    font-size: 1.6rem;
    font-family: "Inter";
}

.footer_logo{
    height:auto;
    width:10rem;
    margin-top: 0.5rem;
}

@media only screen and (max-width: 768px) {

    .Footer_Section{
        flex-direction: column;
        align-items: flex-start;
        margin-left: 5%;
    }

    .Logo_footer{
        display:flex;
        justify-content: flex-start;
        align-items: space-evenly;
    }


}