/* Default styles */
@import url('https://fonts.googleapis.com/css?family=Prata');
.projects_section {
    display: flex;
    flex-direction: column;
    margin-top: 20%;
}

.projects_panel {
    display: flex;
    flex-direction: column;
}

.projects_title {
    margin-bottom: 5%;
    align-self: flex-start;
    margin-left: 5%;
    font-size: 2rem;
}

.panel_card {
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
}

.title,
.photo,
.info {
    display: flex;
    height: 20rem;
    width: 20rem;
    justify-content: center;
    align-items: center;
    font-family: 'Inria Serif';
}

.photo:hover {
    filter: grayscale(100%) !important;
}

.project_button {
    display: inline-block !important;
    font-size: 1.1rem;
    font-family: inherit;
    width: 8rem;
    background: none;
    border: solid black 2px;
    align-self: center;
    margin-top: 3%;
    cursor: pointer;
    font-weight: 100;
    height: 2rem;
    color: black;
    border-radius: 50%;
    line-height: 100%;
}

/* CSS */
.project_button {
  border: 2px solid #422800;
  border-radius: 30px;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  padding: 0 10px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-74:hover {
  background-color: #fff;
}

.button-74:active {
  box-shadow: #422800 2px 2px 0 0;
  transform: translate(2px, 2px);
}

@media (min-width: 768px) {
  .button-74 {
    min-width: 120px;
    padding: 0 25px;
  }
}

.title{
    font-size: 3rem;
    font-family: 'Inria Serif';
    display: flex;
    align-items: flex-start;
    padding: 2%;
    padding-top: 0;
    text-align: center;
    margin-left: 3%;
}
.photo{
    object-fit: cover;
}

.info{
    text-align: left;
    margin-right: 2%;
    padding: 2%;
    display: flex;
    align-items: flex-start;
    padding-top: 0;
}
.info-text{
    margin-top: 20%;
}

/* Media queries for responsiveness */
@media only screen and (max-width: 768px) {
    .title,
    .photo,
    .info {
        width: 100%;
    }
    .info{
        order:2;
    }
    .photo{
        order:3;
    }
    .panel_card {
        flex-direction: column;
    }
}

.project_link {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit the color from the parent element */
}

@media only screen and (max-width: 480px) {
    .projects_title {
        font-size: 1.5rem; /* Adjust font size for smaller screens */
    }

    .project_button {
        width: 100%; /* Make buttons take full width on small screens */
    }
}
