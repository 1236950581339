.Hero{
    display:flex;
    justify-content: space-between;
    margin-bottom: 450px;
}
.img-right{
    width:35rem;
    height:40rem;
    margin-left: 5%;
}
.Hero-left{
    background-color: #92786B;
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color:white;
}
.Hero-right{
    width:150%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.img-left{
    position:absolute;
    top:60%;
    left:30%;
    height:80%;
    width:80%;
    object-fit: cover;
}
.Hero-heading{
    margin-bottom: 45%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;

}
h1,h2 {
    margin: 0;
}
.Logo1{
    text-align: left;
    font-size: 3.5rem;
}
.socials{
    height: 2.5rem;
    width:2.5rem;
}
.icons{
    display: flex;
    width: 35rem;
    justify-content: flex-end;
    margin-top: 1.5%;
}
@media only screen and (max-width: 480px) {
    .Hero{
        flex-direction: column;
    }
    .img-left {
        display: none;
        width: auto;
        max-width: 40%;
        position: relative;
    }
    .img-right{
        width:100vw;
        margin: 0;
    }
    .Hero-right{
        display: flex;
        width: 100vw;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    .icons{
        justify-content: space-evenly;
        width: inherit;
    }
    .socials{
        height: 3rem;
        width: 3rem;
    }
}