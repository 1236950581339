.Card-Section{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 2.5%;
    margin-left: 2.5%;
    width: 100%;
    
}
.photo_port{
    width:20rem;
    height:20rem;
}
.card_heading{
    font-size: 1.5rem;
    margin-bottom: 0;
}

.Card-Section:hover{
    filter: grayscale(100%);
}
.card_desc{
    text-align: left;
    font-weight: 10;
    padding-right: 10%;
}

@media only screen and (max-width: 1000px) {

}