.Contact-Section{
    display: flex;
    background-color: #92786B;
    flex-direction: column;
    color: white;
    margin-top: 15%;
}

.contact_header{
    font-size: 3.5rem;
    margin-top: 5%;
}
.contact_form{
    display: flex;
    flex-direction: column;
    margin-top:7%;
}

.form_element{
    display: flex;
    justify-content: center;
    padding: 2%;
    margin-bottom: 5%;
}
.form_sub{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color:inherit;
    width:20rem;
    margin-left: 5%;
    margin-right: 5%;

}
.input_text{
    align-self: flex-start;
    font-size: larger;
    font-weight: bold;
}

.inputfield{
    background: #92786B ;
    border: none;
    border-bottom: solid white 2px;
    width:100%;
    margin-top: 10%;
}
.inputfield2{
    background: #92786B ;
    border: none;
    border-bottom: solid white 2px;
    width:100%;
    margin-top: 10%;
}

.form_sub2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color:inherit;
    width:50rem
}

#contact_button{
    color:white;
    border-color: white;
    margin-bottom: 3%;
}
#loop-text{
    font-size: 1.2rem;
    padding:10%;
    padding-top: 2%;
}