@font-face {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4TC1O4i0FQItq6fNIhnL.woff) format('woff');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  @import url('https://fonts.googleapis.com/css?family=Inter');
  
nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    position: relative;
    top: 0;
    height:6rem;
}

nav .title {
    font-size: 1.8rem;
    font-weight: bold;
    text-decoration: none;
    color: white;
    height:100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

nav ul {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 150%;
    font-size: 1.2rem;
    background-color: #EDE0D4;
    padding:0;
}

nav ul li {
   list-style: none; 
}

nav ul li a {
    display: block;
    text-decoration: none;
    color: black;
    padding: 0.5rem;
    margin: 0 0.5rem;
}


nav .menu {
    display: none;
    position: absolute;
    top: 1rem;
    right: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 2rem;
}

nav .menu span {
    height: 0.4rem;
    width: 100%;
    background-color: #fff;
    border-radius: 0.1rem;
}

.navbar-rule {
  background-color: black;
  height: 1px;
  margin: 0.5rem 2rem 2rem 2rem;
}
.navback{
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    background-color: #92786B;
}
.nav_button{
    border:none;
    background-color: #EDE0D4;
    font-style: inherit;
    font-size: 1.1rem;
    color: black;
}
#website{
    font-family: 'Outfit' !important;
    font-size: 1.8rem;
    color: black;
    font-weight: light;
    margin: 0 5%;
}

.logo_svg{
    height: 50%;
    width:20%;
    order:-1;
}
.company_title{
    display: flex;
    justify-content: space-between;
}

ul{
    font-family: "Inter";
}


@media (max-width: 480px) {
    nav .menu {
        display: flex;
    }

    nav {
        flex-direction: column;
        align-items: flex-start;
    }

    nav ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
        padding:0;
    }

    nav ul.open {
        display: flex;
        z-index: 2;
    }

    nav ul li {
        width: 100%;
        text-align: center;
    }

    nav ul li a {
        margin: 0.2rem 0.5rem;
    }
}