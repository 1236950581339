@import url('https://fonts.googleapis.com/css?family=Inter');

.About-Section {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 3%;
}

.about {
    margin-bottom: 20px;
}
.about-head {
    -webkit-text-stroke: 0.5px;
    font-family: "Inter";
  }
.about-text {
    padding: 20%; /* Adjusted padding */
    padding-top: 0;
    padding-bottom: 5%;
    font-size: 1.2rem;
    font-family: "Inter";
    text-align: center;
}

.about-head {
    font-weight: 500;
    font-size: 3.4rem;
}

.bg {
    width: 100%;
    max-width: 100vw; /* Ensure the background doesn't exceed the viewport width */
    height: 35rem; /* Set a fixed height for larger screens */
    min-height: 20rem; /* Set a minimum height for smaller screens */
    background-image: url('./parallax.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;
    margin-top: 5%;
}

#about-long{
    padding: 5%;
    text-align: left;
    padding-top: 0;
    padding-bottom: 5%;
}
